import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, DatePicker, Flex, Popover } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DEFAULT_PAGE_SIZE, omitKeysFromObject, PAGINATION, renderJsonPreview, showTotalRecords } from '../../../const/utils'
import moment from 'moment'
import { TRANSACTION_DATE_FORMAT, transformUserIdFormat } from '../Transactions/Transactions.const'
import { fetchDataDeletionTransactions } from '../../../store/dataDeletion/action'
import { DataDeletionTransactionsTableColumns } from './columns'
// import { SearchOutlined } from '@ant-design/icons'
import CopyToClipboard from 'react-copy-to-clipboard'

export const DataDeletionTransactions = () => {
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)

  const dispatch = useDispatch()
  const [userRole, setUserRole] = useState('')
  const dataDeletionLogs = useSelector(state => state?.dataDeletion?.dataDeletionLogs)
  const [filterDateRange, setFilterDateRange] = useState(null)
  // const [selectedRowKeys, setSelectedRowKeys] = useState([])
  // const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
  const [wordEntered, setWordEntered] = useState()
  // const hasSelected = selectedRowKeys.length > 0

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const { role } = userData?.user
    setUserRole(role)
  }, [])

  const handleSearch = (event) => {
    // const searchWord = event.target.value
    // setWordEntered(searchWord)
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    dispatch(fetchDataDeletionTransactions(params))
  }

  useEffect(() => {
    _handleSearchAndFilter()
  }, [])

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    // setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    // ...selectedRowKeys,
    // onChange: onSelectChange
  }

  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  return (
    <div className='bg-white shadow pb-3'>
      <PageHeader
        ghost={false}
        title={`Data Deletion Request`}
      ></PageHeader>
      <Flex gap="middle">
        <Flex gap="middle" vertical>
          <Flex gap="middle" className='h-[40px] ml-4'>
            {/* <Input
              className='w-[450px] h-[40px] ml-4'
              placeholder='Search by User ID or Email'
              value={wordEntered}
              onChange={handleSearch}
              prefix={<SearchOutlined />}
            /> */}
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </Flex>
        </Flex>
        <Flex gap="middle" className='mb-4'>
          <Button className='w-[200px] h-[40px]'
            // disabled={userRole != 'admin'} 
            onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
        </Flex>
      </Flex>
      <Table
        className='z-[0] relative overflow-auto'
        // rowSelection={rowSelection}
        columns={DataDeletionTransactionsTableColumns}
        dataSource={dataDeletionLogs?.result?.map((i, it) => ({
          key: i?._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          status_url: i?.status_url,
          user_info:
            <>
              {i?.userProfile &&
                <>
                  <CopyToClipboard text={i?.userProfile?._id}>
                    <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('User ID copied to Clipboard')}>{transformUserIdFormat(i?.userProfile)}</p>
                  </CopyToClipboard><p>{i?.userProfile?.name} ({i?.userProfile?.status})</p></>
              }
            </>,
          status: i?.status,
          sso_source: i?.sso_source,
          sso_user_id: i?.sso_user_id,
          createdAt: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          updatedAt: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT))
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          // hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: dataDeletionLogs?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div >
  )
}