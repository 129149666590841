import React, { useState, useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Collapse, Button, Radio, Select, Form, Input, Upload, Breadcrumb, Popconfirm, Typography, Table, Modal } from 'antd'
import { QuestionCircleOutlined, PlusSquareFilled, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'
import moment from 'moment'

import { fetchTeamById, updateTeam, fetchTeamMembers, updateTeamStatus, massUpdateTeamStatus, exportAllTeamMembersAsCSV } from '../../../store/teams/actions'
import { fetchAllCommunityForAddEditTeam } from '../../../store/community/actions'
import { fetchPublishedLocations } from '../../../store/locations/actions'

import AddMembers from './invite/AddMembers'
import InviteTeamMembers from './uploadCSV/InviteTeamMembers'

// import { CSVLink } from 'react-csv'
// import { useCSVReader } from 'react-papaparse'
import { BE_FULL_DATE_FORMAT, showTotalRecords } from '../../../const/utils'
import { TEAM_MODE } from './Teams.const'
import { transformUserIdFormat } from '../Transactions/Transactions.const'
import { ACCOUNT_STATUS } from '../Users/Users.const'

import { TimeZone } from '../../../const/sdgs.const'

export const EditTeam = () => {

    const [form] = Form.useForm()
    // const [modalForm] = Form.useForm()
    const { teamId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    // const [selectedRows, setSelectedRows] = useState([])

    // const usersData = useSelector(state => state.users.users)
    // const filteredUserData = useSelector(state => state.users.searchedUsers)
    const teamData = useSelector(state => state.teams.team)
    const teamMembers = useSelector(state => state.teams.teamMembers)
    const communities = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)
    const teamModes = Object.values(TEAM_MODE)
    // const [pinValue, setPinValue] = useState()
    const [visible, setVisible] = useState(false)
    const [visibleTeamMembers, setVisibleTeamMembers] = useState(false)
    // const [confirmLoading, setConfirmLoading] = useState(false)
    // const [userExist, setUserExist] = useState(false)
    // const [formSubmitState, setFormSubmitState] = useState(false)
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    useEffect(() => {
        dispatch(fetchTeamById(teamId))
        dispatch(fetchTeamMembers(teamId))
        dispatch(fetchAllCommunityForAddEditTeam())
        dispatch(fetchPublishedLocations())
    }, [])

    useEffect(() => {
        // dispatch(fetchAllUsers())
    }, [teamMembers])

    useEffect(() => {
        form.setFieldsValue({
            name: teamData?.name,
            community: { label: teamData?.communityId?.name, value: teamData?.communityId?._id },
            city: teamData?.city?._id,
            teamMode: teamData?.teamMode,
            publish: teamData?.publish,
            timezone: teamData?.timezone
        })
    }, [teamData])

    const successCallback = () => {
        navigate('/teams')
    }

    const successUpdateCallback = () => {
        setTimeout(() => {
            dispatch(fetchTeamMembers(teamId))
        }, 500)
    }

    const updateTeams = (values) => {
        const data = {
            name: values.name,
            communityId: values?.community?.value,
            city: values.city,
            teamMode: values.teamMode,
            publish: values.publish,
            timezone: values?.timezone
        }
        dispatch(updateTeam(teamId, data, successCallback))
    }

    const updateStatusUnblock = (user_Id) => {
        const data = {
            teamId,
            userId: user_Id,
            action: "UNBLOCK"
        }
        dispatch(updateTeamStatus(data, successUpdateCallback))
    }

    const updateStatusBlock = (user_Id) => {
        const data = {
            teamId,
            userId: user_Id,
            action: "BLOCK"
        }
        dispatch(updateTeamStatus(data, successUpdateCallback))
    }

    const updateStatusResend = (user_Id) => {
        const data = {
            teamId,
            userId: user_Id,
            action: "RESEND"
        }
        dispatch(updateTeamStatus(data, successUpdateCallback))
    }

    /**
     * #47382 - Dashboard - add a "auto-join" button in the teams section next to "send invite"
     * @param {*} user_Id 
     */
    const updateStatusAutoJoin = (user_Id) => {
        const data = {
            teamId,
            userId: user_Id,
            action: "AUTOJOIN"
        }
        dispatch(updateTeamStatus(data, successUpdateCallback))
    }

    const deleteTeamMember = (user_Id) => {
        const data = {
            teamId,
            userId: user_Id,
            action: "DELETE"
        }
        dispatch(updateTeamStatus(data, successUpdateCallback))
    }

    const _fetchTeamJoinStatus = (_member) => {
        if (_member && _member.teamJoiningStatus) {
            const _statuses = Object.values(_member.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId)
            return _teamcheck ? _teamcheck?.map(i => i?.status) : '-'
        }
        return '-'
    }

    const _fetchTeamJoinStatusAt = (_member) => {
        if (_member && _member.teamJoiningStatus) {
            const _statuses = Object.values(_member.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId)
            return _teamcheck && _teamcheck[0] && _teamcheck[0].updatedAt ? (moment(_teamcheck[0].updatedAt).format(BE_FULL_DATE_FORMAT)) : '-'
        }
        return '-'
    }

    const _isJoinedTeam = (_member) => {
        if (_member && _member.teamJoiningStatus) {
            const _statuses = Object.values(_member.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId && item.status == 'JOINED')
            return _teamcheck && _teamcheck.length > 0
        }
        return false
    }

    const _isBlockedTeam = (_member) => {
        if (_member && _member.teamJoiningStatus) {
            const _statuses = Object.values(_member.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId && item.status == 'BLOCKED')
            return _teamcheck && _teamcheck.length > 0
        }
        return false
    }

    const _isInvitedTeam = (_member) => {
        if (_member && _member.teamJoiningStatus) {
            const _statuses = Object.values(_member.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId && (item.status == 'INVITED' || item.status == 'PENDING'))
            return _teamcheck && _teamcheck.length > 0
        }
        return false
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'User Id',
            dataIndex: 'userId',
            key: 'userId'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            notAllowToSearch: true
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updataedAt'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '120px'
        }
    ]

    /**
     * 
     * @param {*} i 
     * @returns 
     */
    const _renderCTA = (i) => {
        if (i.status === 'DELETED') {
            return <></>
        }
        if (_isInvitedTeam(i)) {
            const _statuses = Object.values(i.teamJoiningStatus)
            const _teamcheck = _statuses.filter(item => item.teamId == teamId)
            const _statusPending = _teamcheck ? _teamcheck?.find(ie => ie?.status === 'PENDING' || ie?.status === 'INVITED') : null
            return (
                <>
                    <Button style={{ color: '#207868', borderColor: '#207868' }} onClick={() => { updateStatusResend(i._id) }} shape="round" size='small'> Resend Invite </Button>
                    {_statusPending && 
                        <Button style={{ color: '#207868', borderColor: '#207868' }} onClick={() => { updateStatusAutoJoin(i._id) }} shape="round" size='small'> Auto Join </Button>
                    }
                </>
            )
        }
        if (_isBlockedTeam(i)) {
            return (<Button style={{ color: '#207868', borderColor: '#207868' }} onClick={() => { updateStatusUnblock(i._id) }} shape="round" size='small'> UnBlock </Button>)
        }
        if (_isJoinedTeam(i)) {
            return (
                <Button onClick={() => { updateStatusBlock(i._id) }} danger shape="round" size='small'> Block </Button>
            )
        }
        return <></>
    }

    const _handleExportAllMembersAsCSV = () => {
        dispatch(exportAllTeamMembersAsCSV(teamId))
    }

    const _handleResendInviteToSelectedMembers = () => {
        if (selectedRowKeys.length > 0) {
            const data = []
            selectedRowKeys.forEach(element => {
                data.push({
                    teamId,
                    userId: element,
                    action: "RESEND"
                })
            })
            dispatch(massUpdateTeamStatus(data, successUpdateCallback))
        } else if (teamMembers.length > 0) {
            const data = []
            teamMembers.forEach(element => {
                data.push({
                    teamId,
                    userId: element._id,
                    action: "RESEND"
                })
            })
            dispatch(massUpdateTeamStatus(data, successUpdateCallback))
        }
    }

    const _userVerifiedStatus = (_user) => {
        const _status = Object.values(ACCOUNT_STATUS).find(i => i.value == _user.status)
        if (_status == ACCOUNT_STATUS.ACTIVE && _user.is_email_verified) {
            return <CheckCircleOutlined className='text-green-500' />
        }
        return <CloseCircleOutlined className='text-red-500' />
    }

    const hasSelected = selectedRowKeys.length > 0
    const hasTeamMembers = (teamMembers && teamMembers.length > 0)

    return (
        <div className='bg-white p-5'>
            <PageHeader
                ghost={false}
                title="Team Info"
            ></PageHeader>
            <Form
                name='NewTeam'
                form={form}
                labelCol={{ span: 4 }}
                onFinish={(values) => updateTeams(values)}
                initialValues={{ publish: false }}
                autoComplete='off'
            >
                <Form.Item
                    label='Community'
                    name='community'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please select community ' }]}
                >
                    <Select
                        showSearch
                        optionFilterProp='label'
                        options={communities?.result?.map(i => ({ label: i?.name, value: i._id }))}
                        placeholder="Select Community"
                        suffixIcon={<CgArrowsV />}
                    />
                </Form.Item>
                <Form.Item
                    label='Team Name'
                    name='name'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please enter Team Name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='City'
                    name='city'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please Select a City' }]}
                >
                    <Select
                        showSearch
                        allowClear
                        options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                    />
                </Form.Item>
                <Form.Item name="teamMode" colon={false} labelAlign="left" label="Team Mode" defaultValue=''
                    rules={[{ required: true, message: 'Team mode is not selected!' }]} extra={`Private: only manual import / add members can join. Public: any one can join via team share url or QR code scan`}
                >
                    <Radio.Group className="text-left">
                        {teamModes.map(({ value, label, desc }, idx) => (
                            <Radio key={value} value={`${value}`}>{`${label}`}</Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    label='Timezone'
                    name='timezone'
                    colon='false'
                    extra={'Timezone will impact logic of closing Team Challenge'}
                >
                    <Select
                        showSearch
                        optionFilterProp='label'
                        options={TimeZone}
                        placeholder="Select Timezone"
                    />
                </Form.Item>

                <div className='flex items-center m-4'>
                    <Typography.Title level={5} className='p-0 !mb-0'>Team Members</Typography.Title>
                    <Button
                        type='link'
                        className='flex items-center'
                        style={{ color: '#207868' }}
                        icon={<PlusSquareFilled />}
                        onClick={() => setVisible(true)}
                    >
                        Add Member
                    </Button>
                    <Button
                        type='link'
                        className='flex items-center'
                        style={{ color: '#207868' }}
                        icon={<PlusSquareFilled />}
                        onClick={() => setVisibleTeamMembers(true)}
                    >
                        Import CSV
                    </Button>
                    <Button type="primary" className="bg-[#207868] px-6" disabled={!hasSelected && !hasTeamMembers} onClick={_handleResendInviteToSelectedMembers}>
                        {hasSelected ? `Resend Invite To ${selectedRowKeys.length} members` : 'Resend Invite to All members'}
                    </Button>

                    <Button type="primary" className="bg-[#207868] ml-5 px-6" disabled={!hasTeamMembers} onClick={_handleExportAllMembersAsCSV}>Export members as CSV
                    </Button>
                </div>

                <Table
                    columns={columns}
                    rowSelection={rowSelection}
                    dataSource={teamMembers?.length > 0 ? teamMembers?.map((i, idx) => ({
                        key: i._id,
                        srno: idx + 1,
                        userId: transformUserIdFormat(i),
                        verified: _userVerifiedStatus(i),
                        email: i.email,
                        name: i.name,
                        updatedAt: _fetchTeamJoinStatusAt(i),
                        status: _fetchTeamJoinStatus(i),
                        action: <div className='flex gap-2 items-center' style={{ minWidth: '120px' }}>
                            {_renderCTA(i)}
                            <Popconfirm
                                title="Are you sure？"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => deleteTeamMember(i._id)}
                                onCancel={() => null}
                                okText="Yes"
                                okType="danger"
                                placement="topLeft"
                                cancelText="Cancel"
                            >
                                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>

                        </div>
                    })) : []}
                    pagination={{
                        showSizeChanger: true,
                        // hideOnSinglePage: true,
                        position: ["none", "bottomRight"],
                        showTotal: showTotalRecords
                    }}
                />

                <Form.Item name="publish" colon={false} labelAlign="left" label="Publish" defaultValue='false'
                    rules={[{ required: true, message: 'Publish is not selected!' }]}
                >
                    <Radio.Group className="text-left">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>

            </Form>

            <AddMembers
                teamId={teamId}
                visible={visible}
                setVisible={setVisible}
                refresh={successUpdateCallback}
            />

            <InviteTeamMembers
                teamId={teamId}
                visibleTeamMembers={visibleTeamMembers}
                setVisibleTeamMembers={setVisibleTeamMembers}
                refresh={successUpdateCallback}
            />

        </div>
    )
}
