import { FETCH_ALL_COMMUNITIES, FETCH_COMMUNITY_BY_ID, FETCH_MY_CORPORATE_INFORMATION } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"

export const fetchAllCommunity = (filters = PAGINATION, callback = null) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/community/admin-view-all?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_COMMUNITIES,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Community Details", e)
      console.log(e)
    })
}

/**
 * 
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchAllCommunityForAddEditTeam = (filters = PAGINATION, callback = null) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/community/admin-view-all?current=${filters?.current || 1}&status=ACTIVE&published=true&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_COMMUNITIES,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Community Details", e)
      console.log(e)
    })
}

export const createCommunity = (rawData, successCallback) => dispatch => {
  ServerApi().post(`/community`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("New Community Created")
        successCallback(res.data)
      } else {
        message.error("Error Creating Community!")
      }
      successCallback()
    })
    .catch(e => {
      message.error("Error Creating New Community", e)
      console.log(e)
    })
}

export const fetchCommunityById = (id) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/community/${id}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_COMMUNITY_BY_ID,
        payload: data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      dispatch(toggleNetworkLoading(false))
      console.log(e)
    })
}

/**
 * #43296 - My Corporate Dashboard Home
 * @returns 
 */
export const fetchMyCorporateProfile = (communityId = '') => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/community/my-corporate-info${communityId ? `/${communityId}` : ''}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_MY_CORPORATE_INFORMATION,
        payload: data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      // dispatch(toggleNetworkLoading(false))
      console.error(e)
    })
}

export const fetchMyCorporateProfileError = () => dispatch => {
  dispatch({
    type: FETCH_MY_CORPORATE_INFORMATION,
    payload: {}
  })
}

export const updateCommunity = (_id, rawData, successCallback) => dispatch => {
  ServerApi().patch(`community/${_id}`, rawData)
    .then(res => {

      if (res.status === 200 || res.status === 201) {
        console.log('Community updated')
        message.success("Community updated.")
      } else {
        console.log('Unable to update')
        message.warning("Unable to update Community.")
      }
      dispatch(fetchAllCommunity(false))
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}

export const deleteCommunityById = (id) => dispatch => {
  ServerApi().delete(`community/${id}`)
    .then(res => {
      message.success("Community Deleted Succesfully!")
      dispatch(fetchAllCommunity())
    })
    .catch(e => {
      message.error(e?.response?.data?.message || "Error Deleting")
      console.log(e)
    })
}

