import ServerApi from "../../utility/ServerApi"
import { toggleNetworkLoading } from "../common/actions"
import { FETCH_DATA_DELETION_TRANSACTIONS } from "./types"

export const fetchDataDeletionTransactions = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/data-deletion/data-deletion-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_DATA_DELETION_TRANSACTIONS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchDataDeletionTransactions] ----err----- ', err)
    })
}