import { FETCH_DATA_DELETION_TRANSACTIONS } from './types'

const initialState = {
    dataDeletionLogs: []
}

const dataDeletionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_DELETION_TRANSACTIONS:
            return { ...state, dataDeletionLogs: action.payload }
        default:
            return state
    }
}

export default dataDeletionReducer