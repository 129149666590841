export const DataDeletionTransactionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'SSO Source',
    dataIndex: 'sso_source',
    key: 'sso_source'
  },
  {
    title: 'SSO User Id',
    dataIndex: 'sso_user_id',
    key: 'sso_user_id'
  },
  {
    title: 'User Profile',
    dataIndex: 'user_info',
    key: 'user_info'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Status Url',
    dataIndex: 'status_url',
    key: 'status_url'
  },  
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  }
]
