import React, { useRef, useState, useEffect } from 'react'
// import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Col, Row, Button, Input, Space, Form, Table, Tag, Select } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
// import PreviewImage from '../../../components/image/PreviewImage'
import { createBroadcasting, createBroadcastingIndividual, deleteBroadcastingItem, fetchBroadcastingDataList, fetchBroadcastingsChallengesList, fetchBroadcastingsCommunitiesList } from '../../../store/broadcasting/action'
import { CgArrowsV } from 'react-icons/cg'
import moment from 'moment'
import { BROADCASTING_STATUS, CHALLENGE_TYPE } from './Broadcasting.const'
import { BE_FULL_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../const/utils'
/**
 * #42756 - Broadcast Tool
 * @returns 
 */
export const BroadcastingTool = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const challenges = useSelector(state => state.broadcasting?.challenges)
    const communities = useSelector(state => state.broadcasting?.communities)
    const bcastData = useSelector(state => state.broadcasting?.data)

    useEffect(() => {
        dispatch(fetchBroadcastingsCommunitiesList())
        dispatch(fetchBroadcastingsChallengesList())
        dispatch(fetchBroadcastingDataList())
    }, [])

    const [selectedCommunity, setSelectedCommunity] = useState([])
    const [selectedChallenges, setSelectedChallenges] = useState([])
    const [selectedTeams, setSelectedTeams] = useState([])
    const [communitiesData, setCommunitiesData] = useState([])
    const [challengesData, setChallengesData] = useState([])
    const [availableChallengesData, setAvailableChallengesData] = useState([])
    const [broadcastingData, setBroadcastingData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    // const [ctaEnable, setCtaEnable] = useState(false)


    useEffect(() => {
        setBroadcastingData(bcastData)
    }, [bcastData])

    useEffect(() => {
        setCommunitiesData(communities)
    }, [communities])

    useEffect(() => {
        setChallengesData(challenges)
    }, [challenges])

    useEffect(() => {
        //teams options based on selected community
        setTeamsData(communitiesData?.find(i => selectedCommunity === i.communityId)?.teams)
        // force reset team selected
        form.setFieldsValue({ teamsId: undefined })
        //reset challenge data
        setAvailableChallengesData(challengesData?.filter(i => i.communityIds?.includes(selectedCommunity)))
        form.setFieldsValue({ challengesId: undefined })
    }, [selectedCommunity])

    const columns = [
        {
            title: 'No',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'Community',
            dataIndex: 'community',
            key: 'community'
            // ...getColumnSearchProps('community')
        },
        {
            title: 'Teams',
            dataIndex: 'teams',
            key: 'teams'
            // ...getColumnSearchProps('team')
        },
        {
            title: 'Challenge',
            dataIndex: 'challenge',
            key: 'challenge'
            // ...getColumnSearchProps('challenge')
        },
        {
            title: 'Challenge Type',
            dataIndex: 'challengeType',
            key: 'challengeType'
            // ...getColumnSearchProps('challenge')
        },
        {
            title: 'Broadcasted At',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Broadcasted By',
            dataIndex: 'broadcastedBy',
            key: 'broadcastedBy'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '40%'
        }
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     fixed: 'right',
        //     width: '120px'
        // }
    ]

    const _successCallback = (data) => {
        //force refresh data
        dispatch(fetchBroadcastingDataList())
    }

    // const _handleDeleteBroadcastingItem = (_id) => {
    //     // dispatch(deleteBroadcastingItem(_id, _successCallback))
    // }

    const _transformType = (_type) => {
        try {
            return Object.values(CHALLENGE_TYPE).find(e => e.value == _type).label
        } catch (e) {
            return `-`
        }
    }

    const _handleCreateNewBroadcasting = (values) => {
        // console.log(`_handleCreateNewBroadcasting `, values)
        const data = {
            communityId: values.communityId,
            teamsId: values.teamsId,
            challengesId: values.challengesId
        }
        dispatch(createBroadcasting(data, _successCallback))
    }

    const _handleCreateNewBroadcastingIndividual = () => {
        const data = {
            communityId: selectedCommunity,
            teamsId: selectedTeams,
            challengesId: selectedChallenges
        }
        console.log(`_handleCreateNewBroadcastingIndividual param `, data)
        dispatch(createBroadcastingIndividual(data, _successCallback))
    }

    // const _renderStatus = (status) => {
    //     const _s = BROADCASTING_STATUS[status]
    //     let _color = 'green'
    //     if (_s.value == BROADCASTING_STATUS.DELETED.value) {
    //         _color = 'red'
    //     } else if (_s.value == BROADCASTING_STATUS.PENDING.value) {
    //         _color = 'green'
    //     } else if (_s.value == BROADCASTING_STATUS.PROCESSED.value) {
    //         _color = 'blue'
    //     }
    //     return <Tag color={_color}>{_s.label}</Tag>
    // }

    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Broadcasting Tool"
                extra={[]}
            ></PageHeader>

            <div className='flex justify-between'>
                <Form
                    name="fBroadcasting_Tool"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => _handleCreateNewBroadcasting(values)}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="grid grid-cols-4 gap-7">
                        <div className="p-2">
                            <Form.Item
                                label=""
                                name="communityId"
                                labelAlign="left"
                                colon={false}
                                extra="Step 1. Please select community"
                            // rules={[{ required: true, message: 'Please select community!' }]}
                            // hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select community"
                                    optionFilterProp='label'
                                    options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                                    suffixIcon={<CgArrowsV />}
                                    onChange={(h) => { setSelectedCommunity(h) }}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <Form.Item
                                label=""
                                name="teamsId"
                                labelAlign="left"
                                colon={false}
                                mode="multiple"
                                className='col-span-3'
                                extra="Step 2. Please select team(s) or leave blank to broadcast Community Challenge"
                            // rules={[{ required: true, message: 'Please select habit!' }]}
                            // hasFeedback
                            >
                                <Select
                                    mode="multiple"
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select Team(s)"
                                    // options={selectedGoal?.habits?.map(j => ({ label: j.name, value: j._id }))}
                                    options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                                    onChange={(h) => { setSelectedTeams(h) }}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <Form.Item
                                label=""
                                name="challengesId"
                                labelAlign="left"
                                colon={false}
                                mode="multiple"
                                className='col-span-3'
                                extra="Step 3. Please select challenge(s)"
                            // rules={[{ required: true, message: 'Please select habit!' }]}
                            // hasFeedback
                            >
                                <Select
                                    mode="multiple"
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select Challenge(s)"
                                    options={availableChallengesData?.map(i => ({ label: i.challengeName, value: i.challengeId }))}
                                    suffixIcon={<CgArrowsV />}
                                    onChange={(h) => { setSelectedChallenges(h) }}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <div className="">
                                <Button disabled={selectedChallenges?.length == 0} className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                                    {'Broadcast Team/Community'}
                                </Button>
                            </div>
                            <div className="pt-2">
                                <Button disabled={selectedChallenges?.length == 0} className="bg-[#207868]" size="large" type="primary" onClick={_handleCreateNewBroadcastingIndividual}>
                                    {'Broadcast Individual'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>

            </div>

            <Table
                className='z-[0] relative'
                columns={columns}
                dataSource={broadcastingData?.map((i, it) => ({
                    key: i._id,
                    srno: ++it,
                    community: i.communityId?.name,
                    teams: i.teams?.map(i => <Tag className='my-0.5' color='blue' key={i?._id}>{i.name}</Tag>),
                    challenge: `${i.challengeId?.name}\r\n(${i.challengeId?._id})`,
                    broadcastedBy: `${i?.broadcastedBy?.name || 'n/a'}-${i?.broadcastedBy?.role || 'n/a'}`,
                    challengeType: _transformType(i.type),
                    // status: _renderStatus(i.status),
                    status: i.remarks?.map((i, idx) => <p>{(idx + 1)}. {i.notes} - {moment(i.createdAt).format(BE_FULL_DATE_FORMAT)}</p>),
                    createdAt: i.createdAt && (moment(i.createdAt).format(BE_FULL_DATE_FORMAT)),
                    action:
                        <div style={{ minWidth: '65px' }}>
                            {/* <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/banners/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip> */}
                            {/* <Popconfirm
                                title="Are you sure？"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => _handleDeleteBroadcastingItem(i._id)}
                                onCancel={() => null}
                                okText="Yes"
                                okType="danger"
                                placement="topLeft"
                                cancelText="Cancel"
                            >
                                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm> */}
                        </div>
                }))}
                pagination={{
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    defaultPageSize: DEFAULT_PAGE_SIZE
                }}
            />
        </div>
    )
}