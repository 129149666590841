import { combineReducers } from 'redux'

import auth from './auth/reducer'
import common from './common/reducer'
import activity from './activity/reducer'
import goals from './goal/reducer'
import habits from './habit/reducer'
import challenges from './challenges/reducer'
import quests from './quests/reducer'
import banners from './banner/reducer'
import messages from './messaging/reducer'
import users from './users/reducer'
import adminUsers from './users/adminUsers/reducer'
import teams from './teams/reducer'
import community from './community/reducer'
import reports from './reports/reducer'
import rewards from './rewards/reducer'
import transactions from './transactions/reducer'
import rewardTransactions from './transactions/rewardTransactions/reducer'
import questTransactions from './transactions/questTransactions/reducer'
import scoringTransactions from './transactions/scoringTransactions/reducer'
import sdgs from './sdgs/reducer'
import locations from './locations/reducer'
import broadcasting from './broadcasting/reducer'
import miaAnimations from './miaAnimations/reducer'
import appVersion from './appVersion/reducer'
import zoho from './zoho/reducer'
import domains from './domainwhitelisted/reducer'
import stepsTrackingLogs from './stepsTrackingLogs/reducer'
import iLight from './iLight/reducer'
import communityReport from './communityReport/reducer'
import pnsQueueLogs from './pnsQueueLogs/reducer'
import devicesBlacklisted from './deviceblacklisted/reducer'
import emailTracking from './emailTracking/reducer'
import requestLogging from './requestLogging/reducer'
import dataDeletion from './dataDeletion/reducer'

const rootReducer = combineReducers({
  auth,
  common,
  activity,
  goals,
  habits,
  challenges,
  quests,
  banners,
  messages,
  users,
  adminUsers,
  teams,
  community,
  reports,
  rewards,
  transactions,
  rewardTransactions,
  questTransactions,
  scoringTransactions,
  sdgs,
  locations,
  broadcasting,
  miaAnimations,
  appVersion,
  zoho,
  domains,
  stepsTrackingLogs,
  iLight,
  communityReport,
  pnsQueueLogs,
  devicesBlacklisted,
  emailTracking,
  requestLogging,
  dataDeletion
})

export default rootReducer