import React, { useState, useEffect, useRef } from 'react'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse, Button, Checkbox, Radio, Form, Input, Upload, Select, AutoComplete, Typography, message } from 'antd'
// import { CgArrowsV } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ServerApi from '../../../utility/ServerApi'
import { createCommunity } from '../../../store/community/actions'
import { GoalTypes, GoalTypesForCreateNewCommunity, ImpactTypes, TimeZone } from '../../../const/sdgs.const'
// import JoditEditor from 'jodit-react'

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import '../../../assets/css/editorStyles.css'
import { isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { fetchAvailCommunityLeaders } from '../../../store/users/adminUsers/actions'

const { Panel } = Collapse

export const NewCommunity = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const searchedUsers = useSelector(state => state.users.searchedUsers)
    // const teamsData = useSelector(state => state.teams.teams)
    const challengesData = useSelector(state => state.challenges.challenges?.result)
    const adminUsers = useSelector(state => state.adminUsers.availCommunityLeaders)

    // const [visible, setVisible] = useState(false)
    const [goalType1, setGoalType1] = useState("")
    const [goalType2, setGoalType2] = useState("")
    const [goalType3, setGoalType3] = useState("")
    const [fileList, setFileList] = useState([])
    const [fileBannerList, setFileBannerList] = useState([])
    const [welcomeMessage, setWelcomeMessage] = useState()

    const filteredAdminUsers = adminUsers?.result?.filter((values) => {
        return values?.role?.includes('community_leader')
    })

    // const editor = useRef(null)
    // const [contents, setContents] = useState('')
    const staticToolbarPlugin = createToolbarPlugin()
    const plugins = [staticToolbarPlugin]

    // const config = {
    //     readonly: false, // all options from https://xdsoft.net/jodit/doc/
    //     askBeforePasteFromWord: true,
    //     autofocus: false,
    //     cursorAfterAutofocus: "end" | "start"
    // }

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    useEffect(() => {
        // dispatch(fetchAllTeams())
        // dispatch(fetchAllChallenges())
        dispatch(fetchAvailCommunityLeaders(null))
    }, [])

    const successcallBack = () => {
        navigate('/communities')
    }

    const createNewCommunity = (values) => {
        values.welcomeMessage = welcomeMessage
        const data = {
            name: values.name,
            pincode: values.pincode,
            welcomeMessage: values.welcomeMessage,
            leaderId: values.leaderId,
            teamIds: values.teamIds,
            communityFeedAvailable: values.communityFeedAvailable,
            publish: values.publish,
            timezone: values.timezone,
            // leaderId: communityData?.leaderId?._id,
            // AccountInfo: {
            //     email: values.email,
            //     password: values.password
            // },
            communityGoals: [
                {
                    goalName: values.goalName1,
                    goalType: values.goalType1,
                    impactType: values.impactType1,
                    description: values.goalDiscription1,
                    challengeIds: values.challengeIds1,
                    goalTarget: values.goalTarget1
                },
                {
                    goalName: values.goalName2,
                    goalType: values.goalType2,
                    impactType: values.impactType2,
                    description: values.goalDiscription2,
                    challengeIds: values.challengeIds2,
                    goalTarget: values.goalTarget2
                },
                {
                    goalName: values.goalName3,
                    goalType: values.goalType3,
                    impactType: values.impactType3,
                    description: values.goalDiscription3,
                    challengeIds: values.challengeIds3,
                    goalTarget: values.goalTarget3
                }
            ],
            participation_kpi: {
                number_of_people_invited: values?.number_of_people_invited ? parseFloat(values?.number_of_people_invited) : 0,
                signup_rate: values?.signup_rate ? parseFloat(values?.signup_rate) : 0,
                participation_rate_1: values?.participation_rate_1 ? parseFloat(values?.participation_rate_1) : 0,
                active_participation_rate_1: values?.active_participation_rate_1 ? parseFloat(values?.active_participation_rate_1) : 0,
                active_participation_rate_2: values?.active_participation_rate_2 ? parseFloat(values?.active_participation_rate_2) : 0
            },
            activity_kpi: {
                activities_completed_per_week: values?.activities_completed_per_week ? parseFloat(values?.activities_completed_per_week) : 0,
                challenges_completed_per_week: values?.challenges_completed_per_week ? parseFloat(values?.challenges_completed_per_week) : 0
            }
        }
        if (fileList[0]) data.logo = fileList[0].logo
        if (fileBannerList[0]) data.banner = fileBannerList[0].banner
        dispatch(createCommunity(data, successcallBack))
    }

    // const handleUserSearch = (user) => {
    // }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }

        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }

        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'community')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        logo: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleBannerChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileBannerList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'community')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileBannerList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        banner: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    const bannerProps = {
        customRequest: handleBannerChange,
        listType: 'picture',
        onRemove: () => setFileBannerList([]),
        fileList
    }

    // const showModal = () => {
    //     setVisible(true)
    // }

    return (
        <>
            <div className="shadow bg-white">
                <Form
                    name="basic"
                    labelCol={{
                        span: 4
                    }}
                    initialValues={{ publish: false, communityFeedAvailable: false }}
                    onFinish={(values) => createNewCommunity(values)}
                    autoComplete="off"
                    className='px-4 py-4'
                >
                    <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3', '4', '5']}>
                        <Panel header={<p className='font-bold text-lg'>Leader Account Info</p>} Info key="1" className="bg-textbackgroundcolor">

                            <div className='flex justify-between'>
                                <Form.Item
                                    labelAlign='left'
                                    label='Leader Account'
                                    className='w-full'
                                    name='leaderId'
                                    colon={false}
                                    rules={[{ required: true, message: 'Please Select a community Leader!' }]}
                                >
                                    <Select
                                        placeholder='Select a Community Leader account'
                                        options={filteredAdminUsers?.map(i => ({ label: i?.name, value: i?._id }))}
                                    ></Select>

                                </Form.Item>
                                <Button
                                    icon={<PlusOutlined />} type="primary" className="bg-[#207868] ml-5"
                                    onClick={() => navigate('/users/admin')}
                                >
                                    Create New Community Leader
                                </Button>
                            </div>

                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>Community</p>} Info key="2" className="bg-textbackgroundcolor">
                            <Form.Item
                                labelAlign="left"
                                label="Community Name"
                                name="name"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter community name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                                labelAlign="left"
                                label="PIN Code"
                                name="pincode"
                                colon={false}
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item> */}
                            <Form.Item labelAlign="left"
                                label="Logo"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.COMMUNITY_LOGO}
                            >
                                <div className='flex justify-end items-center'>
                                    <Upload className='flex space-x-4'{...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                label="Banner Image"
                                colon={false}
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.COMMUNITY_BANNER}
                            >
                                <div className='flex justify-end items-center'>
                                    <Upload className='flex space-x-4'{...bannerProps} fileList={fileBannerList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            {/* <Form.Item
                                labelAlign="left"
                                label="Welcoming Message"
                                name="welcomeMessage"
                                colon={false}
                            >
                                <Input.TextArea showCount rows={3} />
                            </Form.Item> */}
                            <Form.Item
                                label="Welcoming Message"
                                name="welcomeMessage"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter description!' }]}
                                hasFeedback
                                value={null}
                            >
                                {/* <JoditEditor
                                    ref={editor}
                                    value={contents}
                                    config={config}
                                    autoFocus
                                    cursorAfterAutofocus
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => setContents(newContent)} // preferred to use only this option to update the content for performance reasons
                                    // onChange={newContent => { }}
                                /> */}
                                <Editor
                                    toolbar={{
                                        fontFamily: {
                                            options: ["Poppins"]
                                        }

                                    }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    plugins={plugins}
                                    onChange={() => {
                                        const tempData = (editorState && draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                        setWelcomeMessage(tempData)
                                    }

                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                labelAlign='left'
                                label='Timezone'
                                name='timezone'
                                colon='false'
                                extra={'Timezone will impact logic of closing Community Challenge'}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp='label'
                                    options={TimeZone}
                                    placeholder="Select Timezone"
                                />
                            </Form.Item>
                            {/* <div className='flex justify-between '>
                                <Form.Item
                                    labelAlign="left"
                                    label="Community Leader"
                                    name="leaderId"
                                    colon={false}
                                    labelCol={{
                                        span: 4
                                    }}
                                    className='w-full'
                                    rules={[{ required: true, message: 'Please select Team Leader' }]}
                                >
                                    {/* <AutoComplete
                                        optionFilterProp='label'
                                        // showArrow={false}
                                        options={searchedUsers.map(i => ({ label: i.name, value: i._id }))}
                                        onSearch={(text) => handleUserSearch(text)}
                                    >
                                        <>
                                            <Input size='large' placeholder="search / add community leader" />
                                            <Button size='large' onClick={showModal} icon={<PlusOutlined />} className="bg-[#207868] hover:bg-[#0A5043]" type="primary" />
                                        </>
                                    </AutoComplete> 
                                    <Select
                                        showSearch
                                        style={{
                                            width: '100%',
                                            width: '-moz-avaliable',
                                            width: '-webkit-fill-available',
                                            width: 'fill-available'
                                        }}
                                        optionFilterProp='label'
                                        options={searchedUsers?.map(i => ({ label: i?.name || i?.email, value: i?._id }))}
                                        placeholder="search / add community leader"
                                        onSearch={(text) => handleUserSearch(text)}
                                        suffixIcon={<CgArrowsV />}
                                    />
                                </Form.Item>
                                <Button size='medium' onClick={showModal} icon={<PlusOutlined />} className="bg-[#207868] hover:bg-[#0A5043]" type="primary" />
                            </div> */}
                            {/* <Form.Item
                                labelAlign="left"
                                label="Team Name"
                                name="teamIds"
                                colon={false}
                            >
                                <Select
                                    mode='multiple'
                                    optionFilterProp='label'
                                    options={
                                        teamsData.result?.map(i => ({ label: i?.name, value: i?._id }))
                                    }
                                >
                                    <Input />
                                </Select>
                            </Form.Item> */}

                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>Goals</p>} Info key="3" className="bg-textbackgroundcolor" style={{}}>
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <Form.Item
                                        label="Community Goal 1"
                                        name="goalName1"
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter community goal !' }]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal Type"
                                        name="goalType1"
                                        colon={false}
                                        className='w-full'
                                        extra={'Please edit community later to setup Goal type as Challenge'}
                                    // rules={[{ required: true, message: 'Please select Goal Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={GoalTypesForCreateNewCommunity}
                                            placeholder="Select goal type"
                                            onChange={setGoalType1}
                                        />
                                    </Form.Item>

                                    {goalType1 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Impact Type"
                                            name="impactType1"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Impact Type' }]}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp='label'
                                                options={ImpactTypes}
                                                placeholder="Select Impact type"
                                            />
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Description"
                                        name="goalDiscription1"
                                        colon={false}
                                    >
                                        <Input.TextArea showCount rows={2} />
                                    </Form.Item>

                                    {goalType1 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            name="goalTarget1"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    )}

                                    {goalType1 === "Challenge" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Challenges"
                                            name="challengeIds1"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Challenges' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={challengesData?.map(i => ({ label: i?.name, value: i?._id }))}
                                                placeholder="Select Challenges"
                                            />
                                        </Form.Item>
                                    )}
                                </div>

                                <div>
                                    <Form.Item
                                        label="Community Goal 2"
                                        name="goalName2"
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter community goal !' }]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal Type"
                                        name="goalType2"
                                        colon={false}
                                        className='w-full'
                                        extra={'Please edit community later to setup Goal type as Challenge'}
                                    // rules={[{ required: true, message: 'Please select Goal Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={GoalTypesForCreateNewCommunity}
                                            placeholder="Select goal type"
                                            onChange={setGoalType2}
                                        />
                                    </Form.Item>
                                    {goalType2 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Impact Type"
                                            name="impactType2"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Impact Type' }]}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp='label'
                                                options={ImpactTypes}
                                                placeholder="Select Impact type"
                                            />
                                        </Form.Item>
                                    )}

                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Description"
                                        name="goalDiscription2"
                                        colon={false}
                                    >
                                        <Input.TextArea showCount rows={2} />
                                    </Form.Item>

                                    {goalType2 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            name="goalTarget2"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    )}

                                    {goalType2 === "Challenge" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Challenges"
                                            name="challengeIds2"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Challenges' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={challengesData?.map(i => ({ label: i?.name, value: i?._id }))}
                                                placeholder="Select Challenges"
                                            />
                                        </Form.Item>
                                    )}
                                </div>
                                <div>
                                    <Form.Item
                                        label="Community Goal 3"
                                        name="goalName3"
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter community goal !' }]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal Type"
                                        name="goalType3"
                                        colon={false}
                                        className='w-full'
                                        extra={'Please edit community later to setup Goal type as Challenge'}
                                    // rules={[{ required: true, message: 'Please select Goal Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={GoalTypesForCreateNewCommunity}
                                            placeholder="Select goal type"
                                            onChange={setGoalType3}
                                        />
                                    </Form.Item>

                                    {goalType3 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Impact Type"
                                            name="impactType3"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Impact Type' }]}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp='label'
                                                options={ImpactTypes}
                                                placeholder="Select Impact type"
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Description"
                                        name="goalDiscription3"
                                        colon={false}
                                    >
                                        <Input.TextArea showCount rows={2} />
                                    </Form.Item>

                                    {goalType3 === "ImpactReward" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            name="goalTarget3"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    )}

                                    {goalType3 === "Challenge" && (
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Challenges"
                                            name="challengeIds3"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Challenges' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={challengesData?.map(i => ({ label: i?.name, value: i?._id }))}
                                                placeholder="Select Challenges"
                                            />
                                        </Form.Item>
                                    )}
                                </div>
                            </div>
                            <Form.Item
                                label="Community Feed Available"
                                name="communityFeedAvailable"
                                labelAlign="left"
                                colon={false}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Publish"
                                name="publish"
                                labelAlign="left"
                                colon={false}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>Participation KPIs</p>} Info key="4" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Total Number of Community Members"
                                labelCol={{
                                    span: 18
                                }}
                                name="number_of_people_invited"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Joined Community Members"
                                labelCol={{
                                    span: 18
                                }}
                                name="signup_rate"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Participation Rate (Number of people who did at least 1 activity over campaign period)"
                                labelCol={{
                                    span: 18
                                }}
                                name="participation_rate_1"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Active Participation Rate (Number of people who did at least 1 activity per week)"
                                labelCol={{
                                    span: 18
                                }}
                                name="active_participation_rate_1"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Active Participation Rate (Number of people who did at least 2 activities per week)"
                                labelCol={{
                                    span: 18
                                }}
                                name="active_participation_rate_2"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg'>Activity KPIs</p>} Info key="5" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Activities completed per week"
                                labelCol={{
                                    span: 18
                                }}
                                name="activities_completed_per_week"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Challenges completed per week"
                                labelCol={{
                                    span: 18
                                }}
                                name="challenges_completed_per_week"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                className="col-span-4"
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form>
                {/* <AddEditUsers
                    visible={visible}
                    setVisible={setVisible}
                /> */}
            </div>

        </>
    )
}